import { env } from '$lib/env';
import * as Sentry from '@sentry/sveltekit';

const isProd = env.PUBLIC_NODE_ENV === 'production';

Sentry.init({
	dsn: env.PUBLIC_WEB_SENTRY_DSN,
	tracesSampleRate: 0.5,
	integrations: [new Sentry.Replay()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 0.5,
	environment: `client.${env.PUBLIC_NODE_ENV}`,
	enabled: isProd
});

export const handleError = Sentry.handleErrorWithSentry();

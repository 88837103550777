import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [2,3];

export const dictionary = {
		"/": [4],
		"/auth/action": [5,[2]],
		"/auth/forgot-password": [6,[2]],
		"/auth/login": [7,[2]],
		"/auth/register": [8,[2]],
		"/events/access-denied": [11],
		"/events/[id]": [9,[3]],
		"/events/[id]/register": [10,[3]],
		"/registration-forms/[id]": [~12],
		"/registrations/[id]/checkout": [~13]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';